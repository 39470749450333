// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
axios.defaults.baseURL = 'https://heeeo.media-verse.net/'
axios.defaults.spamURL = "https://spam.lib-media.com/"

export const baseURL = axios.defaults.baseURL
export const getToken = sessionStorage.getItem("token")
export const spamURL = axios.defaults.spamURL

export const updateToken = () => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${getToken}`
  }
}


export const getData = createAsyncThunk('appUsers/getData', async params => {
    const page = params.page
    const perPage = params.perPage
    const encodedParam = new URLSearchParams(params).toString()
    axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
    const response = await axios.get(`/admin/getallusers/page/${page}/limit/${perPage}?${encodedParam}`)
    return response.data
  }
)

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
  const response = await axios.get(`/api/users/${id}`)
  return response.data.user
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})


export const getAllLogs = createAsyncThunk('appUsers/getAllLogs', async params => {
  const page = params.page
  const perPage = params.perPage
  const past = params.past
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
  const response = await axios.get(`/admin/alllogs/${past}/page/${page}/limit/${perPage}`)
  return response.data
})

export const getAllPlaces = createAsyncThunk('appUsers/getAllPlaces', async (params) => {
  const encodedParam = new URLSearchParams(params).toString()
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
  const response = await axios.get(`/admin/places?${encodedParam}`)
  return response.data.data
})

export const getAllGroups = createAsyncThunk('appUsers/getAllGroups', async (params) => {
  console.log("getAllGroups")
  const encodedParam = new URLSearchParams(params).toString()
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
  const response = await axios.get(`/admin/groups?${encodedParam}`)
  console.log(response)
  return response.data
})

export const getAllEvents = createAsyncThunk('appUsers/getAllEvents', async () => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
  const response = await axios.get('/admin/events')
  return response.data.data
})

export const getAllReports = createAsyncThunk('appUsers/getAllReports', async params => {
  const page = params.page
  const perPage = params.perPage
  const encodedParam = new URLSearchParams(params).toString()
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
  const response = await axios.get(`/admin/reports/page/${page}/limit/${perPage}?${encodedParam}`)
  return response.data
})

export const getAllCertif = createAsyncThunk('appUsers/getAllCertif', async params => {
  const page = params.page
  const perPage = params.perPage
  const encodedParam = new URLSearchParams(params).toString()
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
  const response = await axios.get(`/admin/certification/page/${page}/limit/${perPage}?${encodedParam}`)
  return response.data
})
//admin/subscriptions/page/1/limit/10

export const getAllSubscriptions = createAsyncThunk('appUsers/getAllSubscriptions', async params => {
  const page = params.page
  const perPage = params.perPage
  const encodedParam = new URLSearchParams(params).toString()
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
  const response = await axios.get(`/admin/subscriptions/page/${page}/limit/${perPage}?${encodedParam}`)
  return response.data
})

export const getAllAdmins = createAsyncThunk('appUsers/getAllAdmins', async () => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
  const response = await axios.get(`/admin/admins`)
  return response.data.data
})

export const getAllSubscriptionLogs = createAsyncThunk('appUsers/getAllSubscriptionLogs', async params => {
  const page = params.page
  const perPage = params.perPage
  delete params["page"]
  delete params["perPage"]
  const encodedParam = new URLSearchParams(params).toString()
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`
  const response = await axios.get(`/admin/getSubscriptionLogs/page/${page}/limit/${perPage}?${encodedParam}`)
  return response.data
})


export const appSlice = createSlice({
  name: 'appData',
  initialState: {
    params: {},
    allData: [],
    selectedUser: null,
    allLogs: [],
    allPlaces: [],
    allEvents: [],
    allReports: [],
    allCertif: [],
    allSubscriptions: [],
    allSubscriptionLogs: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
//      .addCase(getAllData.fulfilled, (state, action) => {
//        state.allData = action.payload
//      })
      .addCase(getData.fulfilled, (state, action) => {
        state.allData = action.payload
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getAllLogs.fulfilled, (state, action) => {
        state.allLogs = action.payload
      })
      .addCase(getAllPlaces.fulfilled, (state, action) => {
        state.allPlaces = action.payload
      })
      .addCase(getAllGroups.fulfilled, (state, action) => {
        state.allGroups = action.payload
      })
      .addCase(getAllEvents.fulfilled, (state, action) => {
        state.allEvents = action.payload
      })
      .addCase(getAllReports.fulfilled, (state, action) => {
        state.allReports = action.payload
      })
      .addCase(getAllCertif.fulfilled, (state, action) => {
        state.allCertif = action.payload
      })
      .addCase(getAllSubscriptions.fulfilled, (state, action) => {
        state.allSubscriptions = action.payload
      })
      .addCase(getAllAdmins.fulfilled, (state, action) => {
        state.allAdmins = action.payload
      })
      .addCase(getAllSubscriptionLogs.fulfilled, (state, action) => {
        state.allSubscriptionLogs = action.payload
      })
  }
})


export default appSlice.reducer
